import { DirectUpload } from '@rails/activestorage';

export default class DirectUploader {
  constructor(file, url, callback = undefined) {
    this.uploader = new DirectUpload(file, url, this);
    this.callback = callback;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.uploader.create((error, blob) => {
        if (error) {
          return reject(error);
        }
        return resolve(blob);
      });
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    if (typeof this.callback === 'function') {
      request.upload.addEventListener('progress', (event) =>
        this.callback(event)
      );
    }
  }
}
