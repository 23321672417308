export default function SlideForHitaimen() {
  const max_width = 400;
  const _width =
    window.innerWidth > max_width
      ? max_width
      : Math.ceil(window.innerWidth * 0.9);

  const _height =
    window.innerWidth > max_width ? 300 : Math.ceil(window.innerWidth * 0.75);

  let json =
    'https://toys-service.s3-ap-northeast-1.amazonaws.com/dist/slideyou.json';

  if (import.meta.env.NODE_ENV === 'development') {
    json = '/slideyou.json';
  }

  const s = {
    tdom: 'slidehitaimen',
    turl: json,
    width: _width,
    height: _height,
    timer: 5000,
    slide_speed: 1000,
  };

  const touchDom = document.getElementById(s.tdom);
  /*  const consoles = document.createElement("div");
  consoles.id="leftcontroles";
  consoles.innerText ="<";
  touchDom.appendChild(consoles);
*/

  fetch(s.turl)
    .then((response) => {
      return response.json();
    })
    .then((arrs) => {
      console.log(arrs);
      arrs.map((v, i) => {
        const b = document.createElement('div');
        b.className = 'slide_list';
        b.style.left = i * s.width + 'px';

        touchDom.appendChild(b);

        const a = document.createElement('a');
        a.href = v.link_url;
        a.target = '_blank';
        a.className = 'alink';
        b.appendChild(a);

        const t = document.createElement('span');
        t.className = 'titles';
        t.innerText = v.title;
        a.appendChild(t);

        const d = document.createElement('span');
        d.innerText = v.desc;
        d.className = 'descriptions';
        a.appendChild(d);

        const im = document.createElement('img');
        im.src = v.image;
        im.className = 'slide_list_img';
        a.appendChild(im);
      });

      let cs = 0;
      const mxs = arrs.length;
      const sl = Array.from(document.getElementsByClassName('slide_list'));

      var animationslide = function () {
        sl.map((v, i) => {
          // const lleft = v.style.left.replace(/[px]/g, '');

          const f_x = cs === mxs - 1 && i === 0 ? 100 : -(cs * 100);
          const e_x = cs === mxs - 1 && i === 0 ? 0 : -(cs * 100 + 100);
          // console.log("mxs is " + mxs + " cs is " + cs + " i is " + i +" : f_x is" + f_x + "/ e_x is " + e_x);

          v.animate(
            [
              {
                transform: `rotate(0) translate3D( ${f_x}%, 0, 0)`,
                color: '#000',
                opacity: 1,
              },
              {
                transform: `rotate(5deg) translate3D( ${f_x}%, 10%, 0)`,
                color: '#000',
                opacity: 0.5,
              },
              {
                transform: `rotate(0) translate3D( ${e_x}%, 0, 0)`,
                color: '#000',
                opacity: 1,
              },
            ],
            {
              duration: s.slide_speed,
              iterations: 1,
              fill: 'forwards',
            }
          );
        });
        //cs = cs + 1;
        cs = cs + 1 > mxs - 1 ? 0 : cs + 1;
      };

      setInterval(animationslide, s.timer);
    })
    .catch((error) => {
      console.log(error);
    });

  const stylecss = `
<style id="slide-for-hitaimen">
#slidehitaimen{
    font-size: 14px;
}
#slidehitaimen{
    display: block;
    margin:0px auto;
    width: ${_width}px;
    height: ${_height}px;
    overflow: hidden;
    align-items: center;
    position: relative;
    border: 2px solid #ddd;
    box-sizing:border-box;
}
#slidehitaimen .slide_list{
    display: flex;
    width: ${_width}px;
    height: ${_height}px;
    align-items: center;
    position: absolute;
}
#slidehitaimen .slide_list a.alink,a.alink:visited,a.alink:hover{
    flex-basis:100%;
    padding: 0.5rem;
    opacity: 0.9;
}
#slidehitaimen .slide_list img{
    width: 100%;
}

#slidehitaimen .slide_list span.titles{
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    padding: 0.5rem 0rem;
    text-shadow: 0px 0px 10px #fff;
    font-weight: 800;
    background-color: #ff0000;
}
#slidehitaimen .slide_list span.descriptions{
  display: block;
  position: absolute;
  bottom: 1px;
  left:0px;
  width: 100%;
  text-align: center;
  background-color: #222;
  color: #fff;
  font-size: 0.8rem;
  padding:0.2rem 0rem;
}
</style>
`;

  if (!document.getElementById('slide-for-hitaimen')) {
    document.head.insertAdjacentHTML('beforeend', stylecss);
  }
}
