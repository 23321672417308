export function loadImageAsync(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = src;
  });
}

export function getScale(image, dest = 1280) {
  if (image.width > dest || image.height > dest) {
    return image.width > image.height
      ? dest / image.width
      : dest / image.height;
  } else {
    return 1;
  }
}

export function drawResizedImageToCanvas(canvas, image) {
  const scale = getScale(image);
  const destWidth = image.width * scale;
  const destHeight = image.height * scale;
  canvas.width = destWidth;
  canvas.height = destHeight;
  canvas
    .getContext('2d')
    .drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      destWidth,
      destHeight
    );
  return canvas;
}
