<template>
  <div class="identity-document-input">
    <input type="file" accept="image/*" @change="onChange" />
    <input
      type="hidden"
      :value="uploadedFileValue"
      :name="setting.name"
      v-if="uploadedFileValue.length > 0"
    />
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import 'blueimp-canvas-to-blob';
import DirectUploader from '../lib/direct_uploader';
import { loadImageAsync, drawResizedImageToCanvas } from '../lib/image';

export default {
  name: 'IdentityDocumentInput',
  props: {
    setting: {
      type: Object,
      default: function () {
        return {
          directUploadUrl: '',
          name: '',
        };
      },
    },
    isAttached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFileValue: '',
    };
  },
  mounted() {
    if (this.isAttached) {
      this.$emit('attached');
    }
  },
  methods: {
    async onChange(event) {
      const file = event.target.files[0];
      try {
        if (!file.type.includes('image/')) {
          throw new Error('File does not match image');
        }
        const image = await loadImageAsync(URL.createObjectURL(file));
        const canvas = drawResizedImageToCanvas(this.$refs.canvas, image);
        this.upload(canvas);
      } catch (e) {
        console.error(e);
      }
    },
    upload(canvas) {
      canvas.toBlob(
        async (blob) => {
          blob.name = `${new Date().getTime()}.jpg`;
          try {
            const uploader = new DirectUploader(
              blob,
              this.setting.directUploadUrl
            );
            const uploaded = await uploader.upload();
            // console.log(uploaded);
            this.uploadedFileValue = uploaded.signed_id;
            this.$emit('attached');
          } catch (e) {
            console.error(e);
          }
        },
        'image/jpeg',
        0.95
      );
    },
  },
};
</script>

<style scoped lang="scss">
.identity-document-input {
  canvas {
    display: none;
  }
}
</style>
